.mobile-view-wrapper {
  display: none;
}

@media (max-width: 768px) {
  .dashboard-table {
    display: none;
  }
  .mobile-view-wrapper {
    display: block;
  }
  .mobile-row {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ccc;
    padding: 15px 10px;

    margin-bottom: 10px;
  }
  .mobile-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
  }
  .mobile-th {
    font-weight: bold;
    color: #333;
    flex: 0 0 120px;
  }
  .mobile-td {
    flex: 1;
    color: #666;
    text-align: right;
  }
  .mobile-cell:last-child .btn-action {
    margin-top: 10px;
  }

  .mobile-table-actions {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
  }
}
