.gri-report h1{

    font-size: 24px;
    font-weight: 600;
    
}

.gri-report {
    color : #000
}


.gri-report h2 {
    font-size: 20px;
    font-weight: 600;
}
.gri-report h3 {
    font-size: 20px;
    font-weight: 600;
}
.gri-report h4 {
    font-size: 14px;
    font-weight: 700;
}
.grid-report p{
    font-size: 14px !important;

}

 /* Adjust width as needed */
.modal-xxl .modal-dialog{
    max-width: 80vw;
    color: #000;
  }
.modal-xxl .modal-dialog h1{
    font-size: 24px;
    font-weight: 600;
}
.modal-xxl .modal-dialog h2 {
    font-size: 20px;
    font-weight: 600;
}
.modal-xxl .modal-dialog h3 {
    font-size: 20px;
    font-weight: 600;
}
.modal-xxl .modal-dialog h4 {
    font-size: 14px;
    font-weight: 700;
}
.modal-xxl .modal-dialog p{
    font-size: 14px !important;

}