.status {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.status-badge {
    width: 8px;
    height: 8px;
    border-radius: 100%;
}

.status-badge.active {
    background-color: #06847B;
}

.status-badge.inactive {
    background-color: #C22D74;
}