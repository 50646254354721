@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Poppins", sans-serif !important;
}

.layout-wrapper {
  position: relative;
}

.layout-wrapper::before {
  content: "";
  background-image: url(./assets/images/auth/bg.jpg);
  position: absolute;
  inset: 0;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.2;
  background: linear-gradient(
    112deg,
    rgba(239, 239, 233, 1) 20%,
    rgba(229, 204, 184, 1) 100%,
    rgba(232, 217, 205, 1) 100%
  );
}

.gov-active::before {
  content: "";

  position: absolute;
  inset: 0;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.2;
  background: rgb(255, 127, 62);
  background: linear-gradient(
    273deg,
    rgba(255, 127, 62, 1) 2%,
    rgba(255, 255, 255, 1) 100%
  );
}

.env-active::before {
  content: "";

  position: absolute;
  inset: 0;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.2;
  background: rgb(136, 214, 108);
  background: linear-gradient(273deg, #37ca39 2%, rgba(255, 255, 255, 1) 100%);
}

.social-active::before {
  content: "";

  position: absolute;
  inset: 0;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.2;

  background: rgb(108, 153, 214);
  background: linear-gradient(273deg, #ffcf0e 2%, rgba(255, 255, 255, 1) 100%);
}

.form-control {
  padding: 5px 15px;
  font-size: 14px;
  border-color: #e5e5e5;
  height: 45px;
  border-radius: 8px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f8f8fb inset !important;
}

.input-group .btn {
  border-color: #e5e5e5;
  background: #f8f8fb;
}

.form-check-input[type="checkbox"] {
  border-color: #e5e5e5;
}

.logo-container {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
  text-decoration: none;
  outline: none;
}

.logo-container > img {
  width: 50px;
  height: 50px;
}

.company-name > span:nth-child(1) {
  font-size: 17px;
  color: #000;
  font-weight: 600;
}

.company-name > span:nth-child(2) {
  color: #74788d;
  font-weight: 600;
}
.cursor {
  cursor: pointer;
}
.divider {
  border-left: 2px solid #e0e0e0;
  height: 100px;
  align-self: center;
}

@media (max-width: 768px) {
  .divider {
    border-left: none;
    border-top: 2px solid #e0e0e0;
    width: 80%;
    height: 0;
    margin: 20px 0;
  }
}

.dashboard-chart-accordion .accordion-item {
  background: transparent;
  border: none;
}
.dashboard-chart-accordion .accordion-button {
  font-size: 13px;
  /* font-weight: 400; */
  height: 40px;
  padding: 0.5rem 1rem;
  max-width: 120px;
  width: 100%;
  border: none;
  color: #fff;
  background-color: #34c38f;
  /* background-color: transparent; */
  outline: none;

  margin: 20px 15px;
  border-radius: 3px;
}
.dashboard-chart-accordion {
  /* margin-bottom: 10px; */
}

.dashboard-chart-accordion .accordion-button :focus {
  background-color: transparent;
  box-shadow: none;
  outline: none;
}

.dashboard-chart-accordion .accordion-button:not(.collapsed) {
  box-shadow: none;
  border: none;
  outline: none;
}

.dashboard-chart-accordion .accordion-header {
  display: flex;
  justify-content: flex-end;
}

.dashboard-chart-accordion .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  width: 1rem;
  height: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
}

.writemode .apexcharts-datalabel {
  writing-mode: vertical-lr;
  text-anchor: end;
  z-index: 9999 !important;
}

.apexcharts-tooltip * {
  white-space: pre-wrap !important;
  max-width: 300px;
}

.donut {
  margin-bottom: 10px;
}

.donut .apexcharts-legend {
  display: flex;
  justify-content: flex-start !important;
  flex-wrap: wrap !important;
  align-items: flex-start !important;
}

.apexcharts-legend {
  display: flex;
  justify-content: flex-start !important;
  flex-wrap: wrap !important;
  align-items: flex-start !important;
  flex-direction: column;
}

.dashboard-histogram-chart .apexcharts-legend {
  justify-content: flex-start !important;
  flex-direction: row !important;
}

.sidebar-active {
  color: #fff !important;
}

/* off canvas css  */

.offcanvas {
  width: fit-content;
  background: none;
}

.offcanvas.show,
.offcanvas.offcanvas-end {
  width: fit-content;
  border: none;
}

.offcanvas-body {
  padding: 0;
}

/* sidebar css  */

.right-side-panel {
  padding: 20px;
  color: #a6b0cf;
}

.right-side-panel h3 {
  color: #a6b0cf;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
  width: 100%;
  font-weight: 400;
}

.right-side-panel p {
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: #6a7187;
  font-weight: 600;
}

.menu-button {
  display: none;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.header-unit-profile-section {
  display: flex;
}

.unit-label-mobile {
  display: none;
}

.profile-container {
  display: none;
}

/* header media query  */
@media screen and (max-width: 992px) {
  .menu-button {
    display: block !important;
    gap: 10px !important;
  }

  .navbar-links {
    display: none !important;
  }
  .header-links {
    gap: 13px !important;
    text-transform: capitalize;
  }

  .header-links h3 {
    font-size: 14px;
  }
  /* sidebar css */

  .navbar-brand-box {
    padding: 0 0.6rem;
    text-align: center;
    /* width: 250px; */
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo h2 {
    font-weight: 600;
    color: #000;
    font-size: 20px;
    text-transform: uppercase;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .logo h2 span {
    font-weight: 200;
    font-size: 10px;
    color: #888;
    padding-top: 5px;
    margin-left: -5px;
  }

  .navbar-brand-box h2 img {
    /* filter: invert(1); */
  }

  .header-unit-profile-section {
    display: none;
  }

  .unit-label {
    display: none;
  }

  .unit-label-mobile {
    display: block;
    margin-right: 10px;
  }

  select#unit-select {
    width: 74px;
  }

  .apexcharts-legend {
    display: flex;
    justify-content: space-between !important;
    flex-wrap: wrap !important;
    align-items: flex-start !important;
  }
  .apexcharts-tooltip * {
    white-space: pre-wrap !important;
    max-width: 200px;
  }

  .vertical-menu {
    top: 0;
  }

  .welcome {
    justify-content: center;
  }

  .welcome-text {
    display: none;
  }

  .welcome-head {
    background: #fff !important;
    justify-content: center;
  }

  .profile-container {
    align-items: center;
    display: block;
  }

  .company-profile-section {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-user-wid {
    display: none;
  }

  .font-weight {
    font-weight: 600;
  }

  .page-content {
    padding: 94px 8px 60px;
  }

  .dashboard-content h3 {
    font-size: 18px;
  }

  .dashboard-content h5 {
    font-size: 1.01rem;
    max-width: 170px;
  }

  .donut-heading {
    max-width: 170px !important;
  }

  .unit-value {
    font-size: 14px !important;
  }

  .water-table-accordion .accordion-body {
    padding: 10px 5px;
  }

  .chooseFile {
    font-size: 10px;
  }

  .employee-button-text {
    display: none;
  }
}

.frequency-date-input {
  width: 25%;
}

.dashboard-filter {
  margin-top: -64px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .dashboard-filter {
    width: 50% !important;
  }
}

@media screen and (max-width: 768px) {
  .frequency-date-input {
    width: 100% !important;
  }

  .dashboard-filter {
    width: 100% !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-bottom: 20px !important;

    margin-top: 0px !important;
  }

  .dashboard-filter label {
    font-size: 12px !important;
    margin: 0 !important;
  }

  .report-download-button {
    justify-content: flex-start !important;
  }

  


}

.dashboard-content h5 {
  max-width: 800px;
}

.donut-heading {
  max-width: 400px;
}

.dashboard-content p {
  word-break: break-word;
}

.highlights-list {
  /* overflow-y: scroll;
  max-height: 100px; */
}

/* .highlights-list::-webkit-scrollbar {
  width: 6px !important;
} */

/* Track */
/* .highlights-list::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
/* .highlights-list::-webkit-scrollbar-thumb {
  background: #ccc;
} */

/* Handle on hover */
/* .highlights-list::-webkit-scrollbar-thumb:hover {
  background: #8f8f8f;
} */

.performance-card {
  min-height: 500px;
}

.tooltip-inner {
  max-width: 400px !important;
}

/* table sticky  */

table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
table tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}

/* Custom dashboard tabs */
.dashboard-tabs {
  /* border-bottom: 1px solid #ddd; */
  margin-bottom: 20px;
  border-bottom: 0px;
  font-size: 16px;
}

/* Active tab and hover states */
.dashboard-tabs .nav-item.show .nav-link,
.dashboard-tabs .nav-link.active {
  color: #fff;
  background-color: #34c38f;
  /* border-color: #ddd #ddd #fff; */
  border-width: 1px 1px 0;
  font-weight: 500;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Tab links */
.dashboard-tabs .nav-link {
  /* border: 1px solid transparent; */
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #000;
  font-weight: 500;
  padding: 0.75rem 1.25rem;
  background-color: transparent;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s,
    box-shadow 0.3s;
}

/* Hover state for tab links */
.dashboard-tabs .nav-link:hover {
  background-color: #34c38f;
  /* border-color: #ddd #ddd #fff; */
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Active tab hover state */
.dashboard-tabs .nav-item.show .nav-link:hover,
.dashboard-tabs .nav-link.active:hover {
  background-color: #298564;
  color: #fff;
}


.new-unit-value {
  font-size: 16px !important;
  font-weight: 600;
  color: #000;
  
}

@media screen and (max-width: 992px) {
  .unit-value {
    font-size: 12px !important;
    max-width: 100px !important;
  }
}

.card {
  margin-bottom: 24px;
  box-shadow: 0 0.75rem 1.5rem rgb(133 133 142 / 19%);
  /* background: #fff; */
  border: 1px solid #eaeaea;
  border-radius: 14px;
}

.text-muted {
  color: #000 !important;
}

.dashboard-card {
  min-height: 559px !important;
  transition: all 0.4s ease-in-out;
}

.dashboard-donut-card {
  min-height: 420px !important;
  transition: all 0.4s ease-in-out;
}

.scrolling-sidebar {
  overflow-y: auto;
}

.scrolling-sidebar::-webkit-scrollbar {
  width: 0px !important;
}

.apexcharts-xaxis text,
.apexcharts-yaxis text {
  fill: #000 !important;
  font-weight: 700 !important;
}

.rt-tr .rt-th.rt-resizable-header.-cursor-pointer:last-child {
  /* width: 230px !important; */
}

.rt-tr-group .rt-tr .rt-td:last-child {
  /* width: 230px !important; */
}

.row-approved {
  background-color: #d4edda;
}

.approval-table .table > thead th {
  background: #d0d9db !important;
}

.approval-table .table > :not(caption) > * > * {
  background-color: #f4f4f4;
}

.approval-table td {
  text-align: center !important;
}

.approval-table th {
  text-align: center !important;
}

/* react select custom css  */

.react-select-custom__menu {
  z-index: 9999 !important;
}

.form-check-input:checked {
  background-color: #4caf50; /* Green background when checked */
  border-color: #4caf50; /* Green border when checked */
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25); /* Green focus shadow */
}

.form-check-input:checked::-webkit-slider-thumb {
  background-color: #4caf50; /* Green thumb when checked */
}

.apexcharts-menu.apexcharts-menu-open {
  opacity: 1;
  pointer-events: all;
  transition: 0.15s ease all;
  width: 111px;
}

.chart-modal-overlay {
  position: fixed; /* Ensure overlay covers the entire screen */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  align-items: center; /* Center the modal vertically */
  justify-content: center; /* Center the modal horizontally */
  z-index: 999; /* Ensure the overlay is on top */
  transition: opacity 0.3s ease-in-out; /* Smooth transition for fading */
}

.chart-modal-content {
  width: 50%;
  height: 80vh;
  background: #fff; /* White background for the modal content */
  border: 0.5px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; /* Adds a shadow effect */
  position: relative; /* To position the close button inside */
  overflow-y: auto; /* Allows scrolling if content exceeds height */
  z-index: 1000; /* Ensure content is above overlay */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition for appearance */
}

.chart-pop:hover {
  scale: 1.02;
  transition: scale 0.3s ease-in-out;
}

/* custom tree  */

.p-treenode-content {
  display: flex !important;
  align-items: center !important;
  gap: 10px;
  margin-bottom: 2px;
  padding: 7px;
}

.p-checkbox-input {
  display: none;
}

.p-highlight .p-checkbox-box {
  background-color: #06b6d4;
  padding: 0px 2px;
  border-radius: 4px;
  border: 2px solid #0acdf0;
}

.p-checkbox-box {
  height: 22px;
  width: 22px;
  padding: 0px 2px;
  border: 2px solid #d1d5db;
  border-radius: 6px;
}

.p-checkbox-box svg {
  color: #fff;
}

.p-tree-container li {
  list-style-type: none;
}

.p-highlight {
  background-color: #ecfeff;
}


.report-download-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}


.new-pie-chart-container {
  display: flex;
  justify-content: center; /* Center the chart */
  align-items: center;
  flex-direction: column; /* Stack chart and legend */
}

.new-pie-chart-container .apexcharts-legend {
  justify-content: flex-start; 
}


