.step-container {
  display: flex;
  border-bottom: 2px solid #f4f8f9;
}


.active-step {
  border-bottom: 2px solid #34c38f;
  color: #21ab79;
}

.number-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  height: 38px;
  width: 38px;
}


.step {
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.step-container.step-small .step.active-step {
  background: #34c38f;
  color: #fff;
}

.step-container.step-small .step {
  font-size: 14px;
  border-bottom: none;
  padding: 15px;
  background: #f5f9fa;
  border-radius: 8px;
}


@media screen and (max-width: 768px) {
  .step {
 
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;

    justify-content: center;
    position: relative;
 
}

.number-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;

  width: calc(50% - 10px);
}


.step-container.step-small .step {
  font-size: 11px;
  border-bottom: none;
  padding: 5px 5px;
  background: #f5f9fa;
  border-radius: 4px;
 
}
  
}