.ReactTable .rt-thead {
  background-color: #f5f9fa;
  padding: 0;
}

.rt-resizable-header-content {
  font-weight: 700 !important;
}

.ReactTable .rt-tbody {
  padding: 0px 0px;
  overflow: hidden;
  overflow-x: auto;
}

.ReactTable{
  border: 1px solid #eff2f7;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  text-align: left;
}

/* .ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-tbody .rt-td:last-child {
  width: auto !important;
  min-width: auto !important;
  max-width: auto !important;
} */

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{
  padding: 0.75rem 0.75rem;
}

.ReactTable .rt-td{
  padding: 0.75rem 0.75rem;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid #eff2f7;

}

.-sort-desc {
  box-shadow: none !important;

  &:before {
    content: "🡫";
    float: right;
  }
}

.-sort-asc {
  box-shadow: none !important;

  &:before {
    content: "🡩";
    float: right;
  }
}

.import-charts-heading {
  display:block;
  max-width:300px;
  word-wrap:break-word;
}
